import { createRouter, createWebHistory } from 'vue-router'
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// 导航守卫
router.beforeEach(async(to, from, next) => {
	let token = localStorage.getItem('tongfei_token');
	if(token && to.path.search("/manage")){
		next();
	}
	if(to.meta.title){
		document.title = to.meta.title
	}
	console.log("导航守卫:", token)
	next();
});

export default router
