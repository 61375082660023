// en.js
export default {
    nav: {
        home: 'HOME',
        //关于同飞
        about: 'ABOUT',
        co_info: 'COMPANY PROFILE',
        organization: 'ORGANIZATIONAL STRUCTURE',
        honor: 'QUALIFICATION HONOR',
        manufacturing: 'EQUIPMENT',
        social: 'SOCIAL RESPONSIBILITY',
        //产品中心
        product: 'PRODUCT',
        product_info: 'PRODUCT DETAILS',
        plan: 'SOLUTIONS',
        plan_info: 'SOLUTIONS DETAILS',

        news: 'NEWS',
        news_type: 'NEWS CLASSIFICATION',
        news_list:'NEWS MORE',
        news_tonfei: 'NEWS TONG FEI',
        news_co: 'CORPORATE NEWS',
        news_product: 'PRODUCT NEWS',
        news_other: 'OTHER NEWS',

        investor: 'INVESTOR RELATIONS',

        service:'SERVICE SUPPORT',

        job: 'RECRUITING',
        contact: 'CONTACT US',
    },
    main: {
        message: "Message",
        display: "Display"
    }
};