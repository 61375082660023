<template>
    <router-view/>
</template>

<style>
html, body, #app, .el-container {
  padding:0px;
  margin:0px;
  /*width:100%;*/
  height:100%;
  font-family: 微软雅黑, Arial, PingFangSC-Light, "Helvetica Neue", Helvetica, "Microsoft Yahei", "Hiragino Sans GB", tahoma, SimSun, sans-serif;
  font-size: 14px;
    color: #333333;
}
</style>
