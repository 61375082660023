// 正式环境
exports.app = {
    Global_Http: "https://qrl-go.tfzl.com",
    Footer_Http: "https://www.tfzl.com",
    CDN_Http: "https://www-cdn.tfzl.com",
};

// 测试环境
// exports.app = {
    // Global_Http: "http://127.0.0.1:8889",
    // Footer_Http: "http://test.cniptv.com",
    // CDN_Http: "http://test-cdn.cniptv.com",
// };