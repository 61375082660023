const routes = [
    {
        path: '/',
        name: '售后支持',
        component: () => import('@/views/Index'),
        meta:{
        //    页面标题
            title: '同飞股份',
        },
    },
    //后台管理
    {
        path:'/manage/login',
        name:'后台管理 - 登录',
        component: ()=> import('@/views/Admin_Login'),
        meta:{
            //    页面标题
            title: '同飞股份',
        },
    },
    {
        path:'/manage/list',
        name:'后台管理 - 用户信息',
        component: ()=> import('@/views/Admin_List'),
        meta:{
            //    页面标题
            title: '同飞股份',
        },
    },
    //错误
    {
        path: '/:catchAll(.*)',
        // name: '/',
        component: () => import('@/views/Index'),
        meta:{
            //    页面标题
            title: '同飞股份',
        }
    },
]

export default routes
