import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import 'dayjs/locale/zh-cn'
import axios from './utils/http.js'
import dayjs from "dayjs"
import i18n from '@/lang/i18n'

const config_file = require("../public/config");

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
}

app.config.globalProperties.$DevMode = config_file.app;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$dayjs = dayjs;
app.use(router)
app.use(ElementPlus, {
    locale:zhCn,
})
app.use(i18n)
app.mount('#app')