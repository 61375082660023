import axios from 'axios'
import { ElLoading, ElMessage } from 'element-plus'
import router from "@/router";
import config_file from "../../public/config";

let loading;

// 加载画面
function startLoading(){
	loading = ElLoading.service({
		lock: true,
		text: '客官老爷请稍后,正在努力加载中...',
		background: 'rgba(0,0,0,0.7)'
	})
}

// 结束加载
function endLoading(){
	loading.close()
}

// 创建实例
const vueHttp = axios.create({
	baseURL: config_file.app.Global_Http,
	timeout : 5000,
});

// 请求拦截
vueHttp.interceptors.request.use((config) => {
	startLoading()
		console.log("请求拦截", config)
		//判断管理员
		if (config.admin){
			const token = localStorage.getItem('tongfei_token')

			if(token){
				config.headers['Authorization'] = token
			}else{
				router.push('/manage/login')
				return config;
			}
			//是否为文件上传
			if(config.type === 'file'){
				console.log("上传文件")
				return config;
			}
		}
		config.data = JSON.stringify(config.data)
		return config
	},
	function(error){
		return Promise.reject(error)
	});

// 响应拦截
vueHttp.interceptors.response.use((response) => {
		endLoading()
		console.log("响应拦截:", response)
		if(response.data["code"] === 99){
			ElMessage.error("授权失败，请先登录")
			router.push({path:'/manage/login'})
		}
		return response;
	},
	((error) => {
		console.log(error)
		endLoading()
		// 获取状态码
		return Promise.reject(error)
	})
)

// 暴露接口
export default vueHttp
