export default {
    nav: {
        home: '首页',
        //关于同飞
        about: '关于同飞',
        co_info: '公司介绍',
        organization: '组织架构',
        honor: '荣誉资质',
        manufacturing: '生产制造',
        social: '社会责任',
        //产品中心
        product: '产品中心',
        product_info: '产品详细资料',
        plan: '解决方案',
        plan_info: '解决方案详情信息',

        news: '新闻中心',
        news_type: '新闻类型',
        news_list: '更多新闻',
        news_tonfei: '同飞新闻',
        news_co: '企业新闻',
        news_product: '产品新闻',
        news_other: '其他新闻',

        investor: '投资者关系',

        service:'服务支持',

        job: '招贤纳士',
        contact: '联系我们',
    },
    main: {
        message: "消息",
        display: "展示"
    }
};